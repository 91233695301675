/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../helpers'
import { Field } from 'formik'
import Radio from '../../../tdi-mc/form/CustomRadio/Radio'

type Props = {
  id: string,
  image: string
  title: string | number
  description: string | number
  status: 'up' | 'down'
  statusValue: number
  statusDesc: string
  progress: number
  progressType: string,
  isActive: boolean,
  onChange?: (id:string) => void 
}

const CardsWidgetApp: FC<Props> = ({
  id,
  image,
  title,
  description,
  status,
  statusValue,
  statusDesc,
  progress,
  progressType,
  isActive = false,
  onChange
}) => {
  const handleChange = () => {
    onChange && onChange(id);
  }
  return (
    <div className='card h-100'>
      <div className='card-header flex-nowrap border-0 pt-9'>
        <div className='card-title m-0'>
          <a href='#' className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
            [APP]: {title}
          </a>
        </div>
        <div className='card-toolbar m-0'>
        <label className="form-check form-check-custom form-check-solid">
            <input className="form-check-input" type="radio" value="" checked={isActive} onChange={handleChange}/>
        </label>
        </div>
      </div>

      <div className='card-body d-flex flex-column px-9 pt-2 pb-8'>
        <div className='fs-2tx fw-bolder mb-3'>{description} <span style={{fontSize: '1rem'}}>사용자</span></div>

        <div className='d-flex align-items-center flex-wrap mb-5 mt-auto fs-6'>
          {status === 'up' && (
            <KTIcon iconName='arrow-up-right' className='fs-3 me-1 text-success' />
          )}

          {status === 'down' && (
            <KTIcon iconName='arrow-down-right' className='fs-3 me-1 text-danger' />
          )}

          <div className={`fw-bolder me-2 ` + (status === 'up' ? 'text-success' : 'text-danger')}>
            {status === 'up' ? '+' : '-'}
            {statusValue}%
          </div>

          <div className='fw-bold text-gray-400'>{statusDesc}</div>
        </div>

        {/* <div className='d-flex align-items-center fw-bold'>
          <span className='badge bg-light text-gray-700 px-3 py-2 me-2'>{progress}%</span>
          <span className='text-gray-400 fs-7'>{progressType}</span>
        </div> */}
      </div>
    </div>
  )
}

export {CardsWidgetApp}
