import React, { useMemo } from 'react';
import clsx from "clsx";
import { FieldProps } from "formik";
import './style.scss';
import { useIntl } from 'react-intl';
interface CustomInputFileProps extends FieldProps {
  label?: string;
  className?: string;
  placeholder?: string;
  isRequired?: boolean,
  valueUrlDefault?: string,
}

export const CustomInputFile = ({
  label,
  className,
  placeholder,
  isRequired = true,
  valueUrlDefault = '',
  field,
  form,
  ...props
}: CustomInputFileProps) => {
  const intl = useIntl();
  const onChange = (event: any) => {
    form.setFieldValue(
      field.name,
      event.target.files[0]
    );
  };

  const urlFile = useMemo(() => {
    return field.value ? URL.createObjectURL(field.value) : '';
  }, [field.value]);

  return (
    <>
      <div className="col-lg-3 col-md-3  fs-mc-16">
        {label && (<label className={clsx('col-form-label p-0 form-custom-label', { 'required': isRequired })}>{label}</label>)}
      </div>
      <div className="col-lg-6 col-md-6">
        <div
          className='image-input image-input-outline'
          data-kt-image-input='true'
        >
          {/* begin::Preview existing avatar */}
          <div
            className='image-input-wrapper w-125px h-125px'
            style={{ backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '50%', overflow: 'hidden' }}
          >
            {urlFile && <img src={urlFile} alt="icom" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover', padding: '10%' }} />}
          </div>
          {/* end::Preview existing avatar */}

          {/* begin::Label */}
          <label
            className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
            data-kt-image-input-action='change'
            data-bs-toggle='tooltip'
            title='배너 변경'
          >
            <i className='bi bi-pencil-fill fs-7 ps-9'></i>

            <input
              {...props}
              className={className}
              name={field.name}
              onChange={onChange}
              placeholder={placeholder}
              type='file'
              accept='.png, .jpg, .jpeg'
            />
          </label>
          {/* end::Label */}

        </div>
        <div className='form-text'>{intl.formatMessage({ id: 'LABEL_ALLOWED_FILE_TYPES' })}</div>
        {form.errors[field.name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{form.errors[field.name] as string}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomInputFile;   
