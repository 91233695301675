import { apiService } from '../../helpers/axios';
import { App, Setting, SettingGroup } from '../models/_setting';
import { ResponseApi, ResponseData,  } from '../models/_public';

export function getApps(params:Partial<Setting>): Promise<ResponseApi<App[]>> {
  return apiService.get(`/apps`, { params })
}

export function getSetting(appId:string, params:Partial<Setting>): Promise<ResponseApi<Setting>> {
  return apiService.get(`/apps/${appId}/configs`, { params })
}

export function postSettingGroup(appId:string, dataForm: Partial<SettingGroup>): Promise<ResponseApi<Setting>>{
  return apiService.put(`/apps/${appId}/configs`, dataForm)
}
