import clsx from "clsx";
import { FieldProps } from "formik";
import './style.scss';
interface CustomInputProps extends FieldProps {
  label?: string;
  className?: string;
  placeholder?: string;
  isRequired?:boolean
}

export const CustomInput = ({
  label,
  className,
  placeholder,
  isRequired = true,
  field,
  form,
  ...props
}: CustomInputProps) => {
  const onChange = (event: any) => {
    form.setFieldValue(
      field.name,
      event.target.value
    );
  };

  const getValue = () => {
    return field.value || '';
  };

  return (
    <>
      <div className="col-lg-3 col-md-3  fs-mc-16">
      {label && (<label className={clsx('col-form-label p-0 form-custom-label', { 'required' : isRequired})}>{label}</label>)}
      </div>
      <div className="col-lg-6 col-md-6">
        <input
          {...props}
          className={className}
          name={field.name}
          value={getValue()}
          onChange={onChange}
          placeholder={placeholder}
        />
        {form.touched[field.name] && form.errors[field.name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{form.errors[field.name] as string}</div>
          </div>
        )}
      </div>

    </>

  );
};

export default CustomInput;   
