import { AxiosResponse } from 'axios';
import { apiService } from '../../helpers/axios';
import { AuthModel } from '../models/_auth';
import { IUser } from '../models/_user';
import { ResponseApi } from '../models/_public';


export function getUserByToken(token: string): Promise<AxiosResponse<ResponseApi<IUser>, any>> {
  return apiService.post<ResponseApi<IUser>>('/auth/login', {
    accessToken: token,
  })
}

export function postLogin( params: {email: string , password: string}): Promise<ResponseApi<AuthModel>> {
  return apiService.post('/auth/login', params);
}

export function postRegister( params: {email: string , password: string}): Promise<ResponseApi<AuthModel>> {
  return apiService.post('/auth/register', params);
}

export function getLogout(): Promise<ResponseApi<IUser>> {
  return apiService.get('/auth/logout', {});
}

export function getVerifyUserByAccessToken(): Promise<ResponseApi<IUser>> {
  return apiService.get('/admin/me', {});
}

export function requestPassword(params: {email: string}): Promise<ResponseApi<IUser>> {
  return apiService.get('/admin/me', {params});
}

export function getRefreshAccessToken(refreshToken: string): Promise<ResponseApi<AuthModel>> {
  return apiService.post('/auth/refresh-token', {
    refreshToken: refreshToken,
  })
}