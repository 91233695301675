/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metacrew/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">    
    {/*begin::Body */}
    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        {/*begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {/*begin::Wrapper */}
            <div className="w-lg-500px p-10">    
            {/*begin::Form */}
              <Outlet />
            {/*end::Form */} 
            </div>
            {/*end::Wrapper */}
        </div>
        {/*end::Form */}       

        {/*begin::Footer */}  
        <div className="w-lg-500px d-flex justify-content-center  px-10 mx-auto">
            {/*begin::Links */}
            <div className="d-flex fw-semibold text-primary fs-base gap-5">
              <a href="/" >약관</a>

              <a href="/" >계획</a>

              <a href="/" >문의</a>
            </div>
            {/*end::Links */}
        </div>
        {/*end::Footer */}
    </div>
    {/*end::Body */}
    
    {/*begin::Aside */}
    <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundImage: `url(${toAbsoluteUrl('/media/demo/1600x1200/1.jpg')})`}}>
        {/*begin::Content */}
        <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">          
            {/*begin::Image */}                
            <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src={toAbsoluteUrl('/media/misc/auth-screens.png')} alt="" />                 
            {/*end::Image */}
        </div>
        {/*end::Content */}
    </div>
    {/*end::Aside */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
