/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, Form, Formik, FormikProps } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import CustomInput from '../../form/CustomInput/CustomInput'
import Radio from '../../form/CustomRadio/Radio'
import RadioGroup from '../../form/CustomRadio/RadioGroup'
import { ICategory } from '../ModalModel'
import { ModalWrapper } from '../ModalWrapper/ModalWrapper'
import { ButtonPrimary } from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import CustomInputFile from '../../form/CustomInputFile/CustomInputFile'
import { useIntl } from 'react-intl'

type Props = {
  show: boolean,
  isLoading?: boolean,
  handleClose: () => void,
  handleSubmit?: (values: ICategory) => void
}


const CategoryFormModal: React.FC<Props> = ({ show = true, isLoading = false, handleClose, handleSubmit, }) => {
  const intl = useIntl();
  const validationSchema = Yup.object().shape({
    icon: Yup.mixed().required(`${intl.formatMessage({ id: 'LABEL_VALIDATE_REQUIRED' })}`),
    name: Yup.string()
      .min(1, `${intl.formatMessage({ id: 'LABEL_VALIDATE_1_30' })}`)
      .max(30, `${intl.formatMessage({ id: 'LABEL_VALIDATE_1_30' })}`)
      .required(`${intl.formatMessage({ id: 'LABEL_VALIDATE_REQUIRED' })}`),
    status: Yup.string().typeError(`${intl.formatMessage({ id: 'LABEL_VALIDATE_ERROR_TYPE' })}`),
  });
  const [loading, setLoading] = useState(false);
  const initialValues: ICategory = {
    name: '',
    status: 'true',
  }
  const onSubmit = (values: ICategory) => {
    handleSubmit && handleSubmit(values);
  };
  return (
    <ModalWrapper show={show}>
      <div className='container-xxl w-lg-800px'>
        <div className='modal-body'>
          <div className="row mb-15 mt-8">
            <div className="col-10 col-lg-10 col-md-10">
              <h1 className='mt-2'>카테고리 등록</h1>
            </div>
            <div className="col-2 col-lg-2 col-md-2 text-end">
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, values }: FormikProps<ICategory>) => (
              <Form className='pb-10'>
                <div className="row mb-9">
                  <Field
                    label='배너'
                    name='icon'
                    type='text'
                    placeholder='배너'
                    autoComplete='off'
                    valueUrlDefault=''
                    className='form-control'
                    component={CustomInputFile}
                  />
                </div>
                <div className="row mb-9">
                  <Field
                    label={intl.formatMessage({ id: 'LABEL_CATEGORY_NAME' })}
                    name='name'
                    type='text'
                    placeholder={intl.formatMessage({ id: 'LABEL_ENTER_CATEGORY_NAME' })}
                    autoComplete='off'
                    className='form-control'
                    component={CustomInput}
                  />
                </div>
                <div className="row mb-7">
                  <RadioGroup
                    id="status"
                    label={intl.formatMessage({ id: 'LABEL_STATUS' })}
                    value={values.status}
                    error={errors.status}
                    touched={touched.status}
                  >
                    <Field
                      component={Radio}
                      name="status"
                      id="status0"
                      valueSet='false'
                      label={intl.formatMessage({ id: 'LABEL_STATUS_UN_ACTIVE' })}
                    />
                    <Field
                      component={Radio}
                      name="status"
                      id="status1"
                      valueSet='true'
                      label={intl.formatMessage({ id: 'LABEL_STATUS_ACTIVE' })}
                    />
                  </RadioGroup>
                </div>
                <div className="row mb-7">
                  <div className="col-lg-3 col-md-3  fs-mc-16">
                  </div>
                  <div className="col-lg-6 col-md-6 text-center">
                    <ButtonPrimary text={intl.formatMessage({ id: 'BUTTON_CREATE' })} type="submit" isLoading={isLoading} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export { CategoryFormModal }

