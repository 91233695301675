/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { IParamsSearch } from '../../../api/models/_public';
import { KTIcon, useDebounce } from '../../../helpers';
import './style.scss';
import { useIntl } from 'react-intl';

type Props = {
  handleSearch?: (s: IParamsSearch) => void,
  value?: string,
  placeholder?: string,
}
const Search: React.FC<Props> = ({ handleSearch, value = '', placeholder = '' }) => {
  const intl = useIntl();
  const handleChange = (e: any) => {
    const val = e.target.value || '';
    handleSearch && handleSearch({ search: val });
  };


  return (
    <div className=''>
      {/* begin::Search */}
      <label className="form-label text-muted  fs-6">{intl.formatMessage({ id: 'LABEL_SEARCH_BY_KEYWORD' })}:</label>
      <div className='d-flex align-items-center position-relative'>
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid form-control-search w-100 p-4 ps-6 pe-14'
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          style={{
            borderRadius: '0.625rem',
            boxShadow: '0 3px 4px rgba(0,0,0,.03)',
            border: '0px',
          }}
        />
        <div className='fs-1 position-absolute end-0 me-6'>
          <KTIcon iconName='magnifier' className='fs-1 pt-3' />
        </div>
      </div>
      {/* end::Search */}
    </div>
  )
}

export { Search };

