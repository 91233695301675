import React from 'react';
import './style.scss';
import { IPagination } from '../../../api/models/_public';
import Pagination from '../paginations/Pagination';
import { SpinnerBox } from '../loading/SpinnerBox/SpinnerBox';
import clsx from 'clsx';

type Row = {
  id: string
}

type Column = {
  title: string | React.ReactNode,
  className?: string,
  classNameComponent?: string,
  width?: number | string,
  label?: string,
  component?: (row: Row) => void,
  click?: (row: Row) => void,
}

type Props = {
  className: string,
  classNameHeader?: string,
  columns?: Column[],
  data?: any[],
  pagination?: IPagination,
  componentBottom?: React.ReactNode,
  isShowPagination?: boolean,
  isHasColAction?: boolean,
  isShowNumber?: boolean,
  isLoading?: boolean,
  isShowColorStart?: boolean,
  widthNo?: number,
  dataTextLoadingColAction?: string,
  onChangePage?: (numberPage:number) => void
}

const PAGINATION_DEFAULT = { page: 1, pageSize:10, total: 0 };
const COLORS_START = ['success', 'primary', 'warning', 'info', 'danger', 'secondary', 'dark'];

const Tables: React.FC<Props> = ({ className, classNameHeader = 'text-muted fs-7 text-uppercase fw-bold', isShowColorStart = false, columns = [], data = [], isLoading = false, pagination = PAGINATION_DEFAULT, widthNo =  100, onChangePage, componentBottom, isShowPagination = true, isHasColAction = false, isShowNumber = true, dataTextLoadingColAction = '' }) => {
  const { page , pageSize , total  } = pagination;
  const numberPageStart = pageSize * Number(page - 1) + 1;
  const handleChangePage = (numberPage:number) => {
    onChangePage && onChangePage(numberPage);
  };
  return (
    <>
      {/* begin::Body */}
      <div className='card-body py-4'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-mc'>
            {/* begin::Table head */}
            <thead className="fs-7 fw-bold">
              <tr className={`${classNameHeader} `}>
                {isShowNumber && <th className='min-w-50px text-center fs-7 fw-bold  ps-3' style={{width: `${widthNo}px`}} key={0}>no.</th>}
                {columns?.map((column, index) => (
                  <th className={`pe-3 fs-7 fw-bold  ${column?.className || ''}`} key={index} style={{ width: `${column?.width}` }}>{column?.title || ''}</th>
                ))}
              </tr>
              <tr >
                <th colSpan={columns?.length + Number(isShowNumber)} className="py-0 px-0" style={{borderBottomColor: 'transparent'}}>
                <div className={clsx(`infinite-loading-outer`, {'is-show-loading': isLoading})}>
                  <div className="infinite-loading-inner"></div>
                </div>
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className="text-gray-600">
              {data?.length > 0 ?
                (
                  <>
                    {data?.map((row: any, key) => (
                      <tr key={row.id || key} className="bg-hover-light">
                        {isShowNumber && <td className={clsx('text-center ps-3')} key={numberPageStart + key}>

                          {isShowColorStart ?
                          (<div className='d-flex align-items-center  justify-content-start'>
                              <span className={`bullet bullet-vertical h-35px bg-${COLORS_START[key % COLORS_START?.length]}  me-3`}></span>
                              <span>{numberPageStart + key}</span>
                          </div>) : (numberPageStart + key) }
                          </td>}
                        {columns?.map((column, index) => (
                          <td className={`fs-6 ${column?.classNameComponent || 'text-center'} pe-3`} key={index + 2} onClick={() => {column?.click && column?.click(row);}}>
                            <>{column?.component ? column?.component(row) : (row[column?.label || ''])}</>
                          </td>
                        ))}
                      </tr>))}
                  </>
                ) : (
                  <tr>
                      <td className='w-100 text-center pt-6' colSpan={columns?.length + Number(isShowNumber)}>번호.데이터</td>
                  </tr>
                )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {isShowPagination && <div className='row g-6 g-xl-9'>
            <div className={`${componentBottom ? 'col-xl-10' : 'col-12'} text-center mt-10 mb-5`}>
              <Pagination page={page} pageSize={pageSize} totalItem={total} onChangePage={handleChangePage} className='justify-content-end' />
            </div>
            {componentBottom && (
            <div className='col-xl-2 col-md-3 text-end'>
              <>{componentBottom}</>
            </div>)
            }
        </div>}
      </div>
      {/* begin::Body */}
    </>
  )
}

export { Tables };
