/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useListApp from '../../../_metacrew/helpers/hooks/app/useListApp'
import { PageTitle } from '../../../_metacrew/layout/core'
import { EngageWidget10 } from '../../../_metacrew/partials/widgets'
import { CardsWidgetApp } from '../../../_metacrew/partials/widgets/_new/cards/CardsWidgetApp'
import { getStartDateEndDate } from '../../../_metacrew/helpers'
import useDashboard from '../../../_metacrew/helpers/hooks/dashboard/useDashboard'
import { IParamsSearch } from '../../../_metacrew/api/models/_public'
import { PageTitleInner } from '../../../_metacrew/partials/tdi-mc'
import { RangePickerCustom } from '../../../_metacrew/partials/tdi-mc/rangePicker/RangePicker'

const DashboardPage: FC = () => {
  const intl = useIntl();
  const { data = [], currentApp, saveCurrentApp } = useListApp();
  const timeoutRef = useRef<any>(null);
  const date = getStartDateEndDate(100);
  const { dataDashboard, handleChangeParams } = useDashboard({ start_date: date[0], end_date: date[1] });

  const [searchParams, setSearchParams] = useState<IParamsSearch>({
    start_date: date[0],
    end_date: date[1]
  });

  const handleSearch = useCallback((params: IParamsSearch) => {
    setSearchParams((_prev) => ({
      ..._prev,
      ...params,
    }));
  }, []);

  useEffect(() => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef?.current);
    }
    timeoutRef.current = setTimeout(() => handleChangeParams(searchParams), 600);
  }, [handleChangeParams, searchParams]);

  const handleOnChange = (idApp: string) => {
    if (currentApp !== idApp) {
      saveCurrentApp(idApp);
    }
  };

  return (<>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='d-flex justify-content-end col-sm-6 col-md-12 col-xl-12 mb-5 mb-xl-5'>
        <PageTitleInner title="대시보드" className="col-12">
          <div className='d-flex' style={{ width: '350px' }}>
            <div className="w-100">
              <RangePickerCustom isBgWhite={true} isLabel={false} handleSearch={handleSearch} value={[searchParams?.start_date || '', searchParams?.end_date || '']} />
            </div>
          </div>
        </PageTitleInner>
      </div>
      {/* begin::Col */}
      {(data?.length > 0) && data?.map((app) => (
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10' key={app?.id}>
          <CardsWidgetApp
            id={app?.id}
            image=''
            title={String(app?.name || '')}
            description={Number(app?.totalUsers || 0)}
            status='up'
            statusValue={100}
            statusDesc=''
            progress={0.5}
            progressType='MRR'
            isActive={!!(currentApp === app?.id)}
            onChange={handleOnChange}
          />
        </div>))}
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-12'>
        <EngageWidget10 className='h-md-100' title={intl.formatMessage({ id: 'MANAGEMENT_SYSTEM' })} />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>)
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU_DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }

