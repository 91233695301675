/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import './style.scss';

type Props = {
  id?: string,
  className?: string,
  isActive?: boolean,
  onChangeActive?: (idItem:string, isActive:boolean) => void
}

const Switch: React.FC<Props> = ({ className = 'justify-content-center', id = '', isActive = false, onChangeActive }) => {
  const handleChangeActive = useCallback((e:any) => {
    onChangeActive && onChangeActive(id, !isActive);
  },[onChangeActive, isActive, id]);
  return (
    <div
      className={`form-check form-check-custom form-check-solid form-switch form-switch-sm form-switch-mc ${className}`}>
      <input
        className='form-check-input'
        type='checkbox'
        checked={isActive}
        onChange={handleChangeActive}
        name='model.app.sidebar.default.fixed.desktop'
      />
    </div>
  );
}

export { Switch };

