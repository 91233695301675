import { FieldProps } from "formik";

interface RadioGroupProps extends FieldProps {
    label: string,
    className?: string;
    placeholder?: string;
}

export const RadioGroup = ({
    value,
    error,
    touched,
    id,
    label,
    className,
    children,
    ...props
}: any) => {

    return (
        <>
            <div className="col-lg-3 col-md-3  fs-mc-16">
                {label}
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="d-flex justify-content-start align-items-center ">
                    {children}
                </div>
                {touched && error && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{error as string}</div>
                    </div>
                )}
            </div>
        </>

    );
};

export default RadioGroup;
