import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../../../app/modules/auth';
import { getApps } from '../../../api/requests/_setting';
import { QUERIES } from '../../crud-helper/consts';

type Params = any

const useListApp = () => {

  const { currentApp = '', saveCurrentApp } = useAuth();
  
  const fetchApps = async ({ queryKey }:any) => {
    const params = queryKey[1];
    const res =  await getApps(params);
    return res?.data;
  };
  
  const { data, refetch } = useQuery({
    queryKey: [QUERIES.APPS_LIST],
    queryFn: fetchApps,
  });

  return {
    data: data || [],
    currentApp,
    saveCurrentApp,
    refetch,
  };
};

export default useListApp;