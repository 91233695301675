import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useAuth } from '../../../../app/modules/auth';
import { fetchDashboard } from '../../../api/requests/_dashboard';
import { QUERIES } from '../../crud-helper/consts';
import { IParamsSearch } from '../../../api/models/_public';
import { getStartDateEndDate } from '../../crud-helper/helpers';

const NUMBER_PAGE_DEFAULT:number = 1;
type Props={
  start_date?: string,
  end_date?: string,
}
const date = getStartDateEndDate(7);

const useDashboard = ({start_date = date[0],  end_date = date[1]}:Props) => {

  const { currentApp } = useAuth();

  const [searchParams, setSearchParams] = useState<IParamsSearch>({
    page: NUMBER_PAGE_DEFAULT, 
    search:'',
    pageSize: 10,
    status: '',
    start_date: start_date,
    end_date: end_date,
    app: currentApp,
  });

  const fetchPaginatedData = async ({ queryKey, pageParam = NUMBER_PAGE_DEFAULT }:any) => {
    const res =  await  fetchDashboard({page: pageParam, ...queryKey[1]});
    return res?.data || {};
  };

  const { status, data, error, isFetching, isPreviousData, refetch, ...root } = useQuery({
    queryKey: [QUERIES.DASHBOARD, searchParams],
    queryFn: fetchPaginatedData,
    keepPreviousData: true,
    staleTime: 5000,
  });

  const handleChangeParams = useCallback((params:IParamsSearch) => {
    setSearchParams((prevState) => {
      return({...prevState, ...params});
    });
  },[]);

  return {
    dataDashboard: data,
    status, 
    error, 
    isFetching, 
    isPreviousData,
    refetch,
    searchParams,
    handleChangeParams,
  };
};

export default useDashboard;