import axios, { AxiosStatic, AxiosResponse } from 'axios';
import {getAuth} from '../auth'
import { getRefreshAccessToken } from '../../api/requests/_auth';

const BASE_URL = process.env.REACT_APP_BASE_URL || ''

const apiService = setupAxios(axios);


const refreshAccessToken = async (): Promise<string | null> => {
  try {
    const auth = getAuth();
    if (auth && auth.refreshToken) {
      const response = await getRefreshAccessToken(auth.refreshToken);
      return response?.data?.accessToken;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

type TypeConfigHeader = {
  key: string | undefined;
  value: string | undefined;
}

function setConfigHeaders(setting:TypeConfigHeader){
  if(setting && setting?.key && apiService){
    //@ts-ignore
    apiService.defaults.headers[setting?.key || ''] = setting?.value;
  }
}

function setupAxios(axiosClient:any):AxiosStatic {

  axiosClient.defaults.baseURL = BASE_URL;

  // Interceptor for request
  axiosClient.interceptors.request.use(
    (config: {headers: {Authorization: string, method: string}, baseURL: string}) => {
      const auth = getAuth();
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`;
      }
      return config
    },
    (error: any) => Promise.reject(error)
  );
  
  // Interceptor for response
  axiosClient.interceptors.response.use(
    (response: AxiosResponse) => {
      return response?.data;
    },
    async (error:any) => {
      const originalRequest = error.config;
      if ((error.response && error.response.status === 401) && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newAccessToken = await refreshAccessToken();
          if (newAccessToken) {
            // Update to save accessToken
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axiosClient(originalRequest);
          }
        } catch (refreshError) {
          // Handle error refresh token
          return Promise.reject(refreshError);
        }
      }
      // return Promise.reject(error);
      return Promise.resolve(error?.response?.data);
      //return error?.response?.data;
    }
  );
  return axiosClient;
};

export { apiService, setConfigHeaders };