/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
import { IOption, renderArrayNumber } from '../../../../constants/room';
import { ButtonPrimary } from '../../form/Buttons/ButtonPrimary/ButtonPrimary';
import CustomInput from '../../form/CustomInput/CustomInput';
import Radio from '../../form/CustomRadio/Radio';
import RadioGroup from '../../form/CustomRadio/RadioGroup';
import CustomSelect from '../../form/CustomSelect/CustomSelect';
import Tagify from '../../form/Tagify/Tagify';
import { IUpdateRoom } from '../ModalModel';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { useIntl } from 'react-intl';

type Props = {
  show: boolean,
  setting?: { limitUserMin: number, limitUserMax: number } | any,
  listOptionCategory: Option[],
  isLoading?: boolean,
  handleClose: () => void,
  handleSubmit?: (values: IUpdateRoom) => void
}

type Option = {
  value: string,
  label: string,
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<Option, true>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z" fill="#111111" />
      </svg>
    </components.DropdownIndicator>
  );
};

const RoomFormModal: React.FC<Props> = ({ show = true, listOptionCategory = [], setting = {}, isLoading = false, handleClose, handleSubmit }) => {
  const intl = useIntl();
  const ARRAY_NUMBER_PEOPLE: IOption[] = renderArrayNumber(Number(setting?.limitUserMin || 3), Number(setting?.limitUserMax || 200), 1);
  const animatedComponents = makeAnimated();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, '5~30자까지 가능 입력해주세요.')
      .max(30, '5~30자까지 가능 입력해주세요.')
      .required(`${intl.formatMessage({ id: 'LABEL_VALIDATE_REQUIRED' })}`),
    numberPeople: Yup.object({
      label: Yup.string(),
      value: Yup.number()
        .typeError(`${intl.formatMessage({ id: 'LABEL_VALIDATE_MUST_NUMBER' })}`)
        .min(Number(setting?.limitUserMin || 3), `${Number(setting?.limitUserMin || 3)}명~${Number(setting?.limitUserMax || 200)}명까지 가능 입력해주세요.`)
        .max(Number(setting?.limitUserMax || 200), `${Number(setting?.limitUserMin || 3)}명~${Number(setting?.limitUserMax || 200)}명까지 가능 입력해주세요.`)
    }).required(`${intl.formatMessage({ id: 'LABEL_VALIDATE_REQUIRED' })}`),
    categories: Yup.object({ label: Yup.string(), value: Yup.string() }).required(`${intl.formatMessage({ id: 'LABEL_VALIDATE_REQUIRED' })}`),
    hashtags: Yup.array().of(Yup.object({ label: Yup.string(), value: Yup.string() })),
    isPublic: Yup.string().typeError(`${intl.formatMessage({ id: 'LABEL_VALIDATE_ERROR_TYPE' })}`),
    password: Yup.string().when('isPublic', {
      is: (isPublic: string | number) => (isPublic === 'false'),
      then: (schema) => schema.min(4, '4~8자리의 비밀번호를 입력해주세요.').max(8, '4~8자리의 비밀번호를 입력해주세요.').required('비밀번호가 필요합니다'),
    }),
  });

  const initialValues: IUpdateRoom = {
    name: '',
    limitUser: ARRAY_NUMBER_PEOPLE[0],
    categories: listOptionCategory[0],
    hashtags: [],
    banner: null,
    isPublic: 'true',
    password: '',
  }
  const onSubmit = (values: IUpdateRoom) => {
    handleSubmit && handleSubmit(values);
  };

  return (
    <ModalWrapper show={show}>
      <div className='container-xxl w-lg-800px'>
        <div className='modal-body'>
          <div className="row mb-15 mt-8">
            <div className="col-10 col-lg-10 col-md-10">
              <h1 className='mt-2'>{intl.formatMessage({ id: 'BUTTON_CREATE_ROOM' })}</h1>
            </div>
            <div className="col-2 col-lg-2 col-md-2  text-end">
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, values }: FormikProps<IUpdateRoom>) => (
              <Form className='pb-10'>
                <div className="row mb-7">
                  <Field
                    label={intl.formatMessage({ id: 'LABEL_ROOM_NAME' })}
                    name='name'
                    type='text'
                    placeholder={intl.formatMessage({ id: 'LABEL_ENTER_ROOM_NAME' })}
                    autoComplete='off'
                    className='form-control'
                    component={CustomInput}
                  />
                </div>
                <div className="row mb-7">
                  <Field
                    label={intl.formatMessage({ id: 'LABEL_NUMBER_OF_PEOPLE_CHATTING' })}
                    name="limitUser"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    defaultValue={ARRAY_NUMBER_PEOPLE[0]}
                    options={ARRAY_NUMBER_PEOPLE}
                    components={{ ...animatedComponents, DropdownIndicator }}
                    component={CustomSelect}
                    placeholder={intl.formatMessage({ id: 'LABEL_NUMBER_OF_PEOPLE_CHATTING' })}
                    isMulti={false}
                  />
                </div>
                <div className="row mb-7">
                  <Field
                    label={intl.formatMessage({ id: 'LABEL_CATEGORY' })}
                    name='categories'
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect={true}
                    components={{ ...animatedComponents, DropdownIndicator }}
                    defaultValue={listOptionCategory[0]}
                    options={listOptionCategory}
                    component={CustomSelect}
                    isMulti={false}
                  />
                </div>
                <div className="row mb-7">
                  <Field
                    label={intl.formatMessage({ id: 'LABEL_HASHTAG' })}
                    name="hashtags"
                    className='form-control form-custom-input-tagify'
                    components={{ ...animatedComponents, DropdownIndicator }}
                    defaultValue={[]}
                    component={Tagify}
                    isMulti
                  />
                </div>
                <div className="row mb-7">
                  <RadioGroup
                    id="isPublic"
                    label={intl.formatMessage({ id: 'LABEL_STATUS' })}
                    value={values.isPublic}
                    error={errors.isPublic}
                    touched={touched.isPublic}
                  >
                    <Field
                      component={Radio}
                      name="isPublic"
                      id={"isPublic0"}
                      valueSet='true'
                      label={intl.formatMessage({ id: 'LABEL_PUBLIC' })}
                    />
                    <Field
                      component={Radio}
                      name="isPublic"
                      id="isPublic1"
                      valueSet='false'
                      label={intl.formatMessage({ id: 'LABEL_PRIVATE' })}
                    />
                  </RadioGroup>
                </div>
                {(values.isPublic === 'false') && (<div className="row mb-7">
                  <Field
                    label='비밀번호'
                    name='password'
                    type='password'
                    className='form-control'
                    placeholder='4~8자리의 비밀번호를 입력해주세요.'
                    component={CustomInput}
                  />
                </div>)}
                <div className="row mb-7">
                  <div className="col-lg-3 col-md-3   fs-mc-16">
                  </div>
                  <div className="col-lg-6 col-md-6  text-center">
                    <ButtonPrimary text={intl.formatMessage({ id: 'BUTTON_CREATE' })} type="submit" isLoading={isLoading} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export { RoomFormModal };

