/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { WithChildren } from '../../../helpers'

type Props = {
  title?: string,
  className?: string,
}

const PageTitleInner: React.FC<Props & WithChildren> = ({ children, className = '', title = '' }) => {
  return (
    <div className={`${className}`}>
      <div id="kt_app_toolbar" className="app-toolbar pb-3 pb-lg-6">
        <div id="kt_app_toolbar_container" className="d-flex flex-stack container-fluid ps-0 pe-0" >
          <div id="kt_page_title" data-kt-swapper="true" data-kt-swapper-mode="prepend"
            className="page-title d-flex flex-wrap me-3 flex-column justify-content-center">
            <h1 className="page-heading d-flex text-dark fw-bold fs-2 my-0 flex-column justify-content-center">{title}</h1>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export { PageTitleInner }

