/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='chart-simple'
        title={intl.formatMessage({ id: 'MENU_DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> */}
      <SidebarMenuItemWithSub
        to='/room'
        title={intl.formatMessage({ id: 'CHAT_ROOM_MANAGEMENT' })}
        icon='people'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/room/multi' title={intl.formatMessage({ id: 'MULTI_ROOM_LIST' })} hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub
        to='/log'
        title='채팅 로그 관리'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/log/individual' title='개인' hasBullet={false} />
        <SidebarMenuItem to='/log/multi' title='멀티' hasBullet={false} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub
        to='/management'
        title={intl.formatMessage({ id: 'FILTER_MANAGEMENT' })}
        icon='chart'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/management/category' title={intl.formatMessage({ id: 'CATEGORY_MANAGEMENT' })} hasBullet={true} />
        <SidebarMenuItem to='/management/hashtag' title={intl.formatMessage({ id: 'HASHTAG_MANAGEMENT' })} hasBullet={true} />
        <SidebarMenuItem to='/management/prohibited-words' title={intl.formatMessage({ id: 'PROHIBITED_MANAGEMENT' })} hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export { SidebarMenuMain }
