import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import { FieldProps } from "formik";
import { useCallback, useRef } from "react";
import './style.scss';

interface Option {
  label: string | number;
  value: string | number;
}

interface TagifyProps extends FieldProps {
  label?: string;
  options: Option[];
  isMulti?: boolean;
  className?: string;
  defaultValue?: string[] | string,
  placeholder?: string;
  settings?: any
}

export const Tagify = ({
  label,
  className,
  placeholder,
  field,
  form,
  options,
  defaultValue,
  settings = {},
  ...props
}: TagifyProps) => {
  
  const tagifyRef = useRef();


  const onChange = useCallback((event: any) => {
    const valueArr = event.detail.tagify.value?.map((item:any) => ({value: String(item?.value).replace(/\s/g, ''), label: item?.value}));
    form.setFieldValue(
      field.name,
      valueArr
    );
  },[]);

  const getValue = () => {
    return field.value || '';
  };

  return (
    <>
      <div className="col-lg-3 col-md-3   fs-mc-16">
        {label && (<label className='col-form-label p-0 form-custom-label '>{label}</label>)}
      </div>
      <div className="col-lg-6 col-md-6 ">
      <>
          <Tags
            {...props}
            className={className}
            name={field.name}
            tagifyRef={tagifyRef}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        </>
        {form.touched[field.name] && form.errors[field.name] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{form.errors[field.name] as string}</div>
          </div>
        )}
      </div>
    </>

  );
};

export default Tagify;
