/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, Form, Formik, FormikProps } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import CustomInput from '../../form/CustomInput/CustomInput'
import CustomSwitch from '../../form/CustomSwitch/CustomSwitch'
import { IRoomSetting } from '../ModalModel'
import { ModalWrapper } from '../ModalWrapper/ModalWrapper'
import { ButtonPrimary } from '../../form/Buttons/ButtonPrimary/ButtonPrimary'
import { useIntl } from 'react-intl'

type Props = {
  show: boolean,
  isLoading?: boolean,
  defaultSetting?: IRoomSetting | any,
  handleClose: () => void,
  handleSubmit?: (values: IRoomSetting) => void
}



const RoomFormSettingModal: React.FC<Props> = ({ show = true, isLoading = false, defaultSetting = {}, handleClose, handleSubmit }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    limitUserMin: Yup.number()
      .typeError(`${intl.formatMessage({ id: 'LABEL_VALIDATE_MUST_NUMBER' })}`)
      .min(3, `${intl.formatMessage({ id: 'LABEL_VALIDATE_MIN_3_99999999' })}`)
      .max(99999999, `${intl.formatMessage({ id: 'LABEL_VALIDATE_MIN_3_99999999' })}`).required(`${intl.formatMessage({ id: 'LABEL_VALIDATE_REQUIRED' })}`),
    limitUserMax: Yup.number()
      .typeError(`${intl.formatMessage({ id: 'LABEL_VALIDATE_MUST_NUMBER' })}`)
      .min(3, `${intl.formatMessage({ id: 'LABEL_VALIDATE_MIN_3_99999999' })}`)
      .max(99999999, `${intl.formatMessage({ id: 'LABEL_VALIDATE_MIN_3_99999999' })}`).required(`${intl.formatMessage({ id: 'LABEL_VALIDATE_REQUIRED' })}`),
    availability: Yup.boolean().typeError(`${intl.formatMessage({ id: 'LABEL_VALIDATE_ERROR_TYPE' })}`),
  });
  const initialValues: IRoomSetting = {
    limitUserMin: Number(defaultSetting?.limitUserMin || 3),
    limitUserMax: Number(defaultSetting?.limitUserMax || 200),
    availability: !!(defaultSetting?.availability),
  }
  const onSubmit = (values: IRoomSetting) => {
    handleSubmit && handleSubmit(values);
  };
  return (
    <ModalWrapper show={show}>
      <div className='container-xxl w-lg-800px'>
        <div className='modal-body'>
          <div className="row mb-20 mt-8">
            <div className="col-10 col-lg-3 col-md-3 order-1 order-md-1">
              <h1 className='pt-2'>{intl.formatMessage({ id: 'LABEL_CREATE_SETTING_ROOM' })}</h1>
            </div>
            <div className="col-12 col-lg-7 col-md-7 order-3 order-md-2">
              <div className='text-muted fw-bold fs-5 pt-0 pt-md-2' style={{ lineHeight: '30px' }}>
                {intl.formatMessage({ id: 'LABEL_PLEASE_SET_CATE_HASHTAG_SETTING' })}
              </div>
            </div>
            <div className="col-2 col-lg-2 col-md-2 text-end order-2 order-md-3">
              {/* begin::Close */}
              <div className='btn btn-icon btn-sm ms-2' onClick={handleClose}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.5 7.5L7.5 22.5M7.5 7.5L22.5 22.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              {/* end::Close */}
            </div>
          </div>
          {/* begin::Search */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, values }: FormikProps<IRoomSetting>) => (
              <Form className='pb-10'>
                <div className="row mb-7">
                  <Field
                    label={intl.formatMessage({ id: 'LABEL_MINIMUM_NUMBER_PEOPLE' })}
                    name='limitUserMin'
                    type='text'
                    placeholder={intl.formatMessage({ id: 'LABEL_MINIMUM_NUMBER_PEOPLE' })}
                    autoComplete='off'
                    isRequired={true}
                    className='form-control'
                    component={CustomInput}
                  />
                </div>
                <div className="row mb-7">
                  <Field
                    label={intl.formatMessage({ id: 'LABEL_MAXIMUM_NUMBER_PEOPLE' })}
                    name='limitUserMax'
                    type='text'
                    placeholder={intl.formatMessage({ id: 'LABEL_MAXIMUM_NUMBER_PEOPLE' })}
                    isRequired={true}
                    autoComplete='off'
                    className='form-control'
                    component={CustomInput}
                  />
                </div>
                {/* <div className="row mb-7">
                  <Field
                    label='공개 가능 여부'
                    name='availability'
                    placeholder='공개 가능 여부'
                    className='justify-content-start'
                    component={CustomSwitch}
                  />
                </div> */}
                <div className="row mb-7">
                  <div className="col-lg-3 col-md-3  fs-mc-16">
                  </div>
                  <div className="col-lg-6 col-md-6 text-center">
                    <ButtonPrimary text={intl.formatMessage({ id: 'BUTTON_CREATE' })} type="submit" isLoading={isLoading} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  )
}

export { RoomFormSettingModal }

