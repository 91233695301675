import clsx from 'clsx'
import { useFormik } from 'formik'
import { useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { postLogin } from '../../../../_metacrew/api/requests/_auth'
import { useAuth } from '../core/Auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('잘못된 이메일 형식') // Wrong email format
    .min(3, '최소 3개 기호') // Minimum 3 symbols
    .max(50, '최대 50개 기호') // Maximum 50 symbols
    .required('이메일은 필수입니다'), // Email is required
  password: Yup.string()
    .min(3, '최소 3개 기호') // Minimum 3 symbols
    .max(50, '최대 50개 기호') // Maximum 50 symbols
    .required('비밀번호가 필요합니다'), // Password is required
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const params = {
          email: values.email,
          password: values.password
        }
        const { statusCode, data } = await postLogin(params);
        if(statusCode === 200){
          saveAuth(data)
          setCurrentUser(data?.admin)
        }else{
          toast('이메일 또는 비밀번호가 잘못되었습니다.');
          setStatus('The login details are incorrect')
          setSubmitting(false)
          setLoading(false)
        }
        
      } catch (error) {
        console.error(error)
      }
    },
  })

  return (
    <form
    className='form w-100'
    onSubmit={formik.handleSubmit}
    noValidate
    id='kt_login_signin_form'
  >
    {/* begin::Heading */}
    <div className='text-center mb-11'>
      <h1 className='text-dark fw-bolder fs-3hx mb-3'>로그인</h1>
      <div className='text-gray-500 fw-semibold fs-6'>관리하려면 로그인하세요.</div>
    </div>
    {/* begin::Form group */}
    <div className='fv-row mb-8'>
      <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
      <input
        placeholder='Email'
        {...formik.getFieldProps('email')}
        className={clsx(
          'form-control bg-transparent',
          { 'is-invalid': formik.touched.email && formik.errors.email },
          {
            'is-valid': formik.touched.email && !formik.errors.email,
          }
        )}
        type='email'
        name='email'
        autoComplete='off'
      />
      {formik.touched.email && formik.errors.email && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        </div>
      )}
    </div>
    {/* end::Form group */}

    {/* begin::Form group */}
    <div className='fv-row mb-3'>
      <label className='form-label fw-bolder text-dark fs-6 mb-0 '>비밀번호</label>
      <input
        placeholder='비밀번호'
        type='password'
        autoComplete='off'
        {...formik.getFieldProps('password')}
        className={clsx(
          'form-control bg-transparent',
          {
            'is-invalid': formik.touched.password && formik.errors.password,
          },
          {
            'is-valid': formik.touched.password && !formik.errors.password,
          }
        )}
      />
      {formik.touched.password && formik.errors.password && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.password}</span>
          </div>
        </div>
      )}
    </div>
    {/* end::Form group */}

    {/* begin::Wrapper */}
    <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
      <div />

      {/* begin::Link */}
      {/* <Link to='/auth/forgot-password' className='link-primary'>
        비밀번호를 잊으 셨나요 ?
      </Link> */}
      {/* end::Link */}
    </div>
    {/* end::Wrapper */}

    {/* begin::Action */}
    <div className='d-grid mb-10'>
      <button
        type='submit'
        id='kt_sign_in_submit'
        className='btn btn-primary'
        disabled={formik.isSubmitting || !formik.isValid}
      >
        {!loading && <span className='indicator-label'>로그인</span>}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
    {/* end::Action */}

    {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
      Not a Member yet?{' '}
      <Link to='/auth/registration' className='link-primary'>
        Sign up
      </Link>
    </div> */}
  </form>
  )
}
