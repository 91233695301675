/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { WithChildren } from '../../../../helpers'

type Props = {
  show: boolean,
  className?: string,
  handleClose?: () => void,
  dialogClassName?: string,
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalWrapper: React.FC<Props & WithChildren> = ({ children, show = true, handleClose, className = '', dialogClassName = 'h-auto modal-dialog-centered mw-900px' }) => {
  return createPortal(
    <Modal
      className={className}
      aria-hidden='true'
      dialogClassName={dialogClassName}
      show={show}
    >
      <div className='modal-content shadow-none'>
        {children}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ModalWrapper }

