import { FC, Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metacrew/assets/ts/_utils'
import { WithChildren } from '../../_metacrew/helpers'
import { MasterLayout } from '../../_metacrew/layout/MasterLayout'
import { MenuTestPage } from '../pages/MenuTestPage'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {

  const RoomPage = lazy(() => import('../modules/room/RoomPage'))
  const LogPage = lazy(() => import('../modules/log/LogPage'))
  const ManagementPage = lazy(() => import('../modules/management/ManagementPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='room/*'
          element={
            <SuspensedView>
              <RoomPage />
            </SuspensedView>
          }
        />
        <Route
          path='log/*'
          element={
            <SuspensedView>
              <LogPage />
            </SuspensedView>
          }
        />
        <Route
          path='management/*'
          element={
            <SuspensedView>
              <ManagementPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

