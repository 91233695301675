import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

type PaginationProps = {
  page: number;
  pageSize: number;
  totalItem: number;
  className?: string;
  onChangePage?: (numberPage:number) => void,
};
const MAX_TOTAL_PAGE_SHOW = 10;

const getPageNumbers = (totalPages:number, page:number) => {
  const pageNumbers: number[] = [];
  if (totalPages <= MAX_TOTAL_PAGE_SHOW) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (page <= 6) {
      for (let i = 1; i <= 7; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push(-1);
      pageNumbers.push(totalPages);
    } else if (page >= totalPages - 5) {
      pageNumbers.push(1);
      pageNumbers.push(-1);
      for (let i = totalPages - 6; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push(-1);
      for (let i = page - 2; i <= page + 2; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push(-1);
      pageNumbers.push(totalPages);
    }
  }
  return pageNumbers;
};

const Pagination: React.FC<PaginationProps> = ({
  page,
  pageSize,
  totalItem,
  className = '',
  onChangePage,
}) => {
  const totalPages = totalItem > 0 ? Math.ceil(totalItem / pageSize): 1;

  const pageNumbers = getPageNumbers(totalPages, page);

  const handleChangePage = useCallback((numberPage:number) => {
   if(numberPage > 0 && numberPage <= totalPages){
    onChangePage && onChangePage(numberPage);
   }
  },[onChangePage, totalPages]);

  return (
    <div className={`d-flex flex-stack flex-wrap ${className}`}>
      <ul className="pagination">
        <li className="page-item previous">
          <Link to='/' className="page-link" onClick={(e:any) => {e.preventDefault(); handleChangePage(page - 1)}}>
            <i className="previous"></i>
          </Link>
        </li>
        {pageNumbers.map((pageNumber, index) => (
          <li
            key={index}
            className={`page-item ${
              pageNumber === page ? 'active' : ''
            } ${pageNumber === -1 ? 'disabled' : ''}`}
          >
            <Link to='/' className="page-link" onClick={(e:any) => {e.preventDefault(); handleChangePage(pageNumber)}}>
              {pageNumber === -1 ? '...' : pageNumber}
            </Link>
          </li>
        ))}
        <li className="page-item next">
        <Link to='/' className="page-link" onClick={(e:any) => {e.preventDefault();handleChangePage(page + 1)}}>
            <i className="next"></i>
        </Link>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;