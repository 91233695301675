import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import './style.scss';

type Props = {
  title?: string,
  content?: string,
  show: boolean
  handleClose?: () => void,
  handleYes?: () => void,
}

const ConfirmModal: React.FC<Props> = ({ show = true, title = '', content = '', handleClose, handleYes }) => {
  const intl = useIntl();
  const onNo = useCallback((event: any) => {
    event.preventDefault();
    handleClose && handleClose();
  }, []);
  const onYes = (event: any) => {
    event.preventDefault();
    handleYes && handleYes();
  };
  return (
    <ModalWrapper show={show} className='noti-modal' dialogClassName='h-auto modal-dialog-centered mw-400px' >
      <div className='container-md w-lg-400px p-0'>
        <div className='modal-body'>
          <div className="row">
            <div className="col-12">
              <h3 className='mt-2 text-dark'>{title}</h3>
            </div>
          </div>
          {/* begin::Search */}
          <div className="d-flex flex-column justify-content-center align-items-left">
            <div className="modal-content-text text-dark mb-8 mt-3">{content}</div>
            <div className='w-100 d-flex justify-content-end align-items-center'>
              <button className='btn btn-secondary btn-sm fw-bold px-8' type="button" onClick={onNo}>{intl.formatMessage({ id: 'BUTTON_CANCEL' })}</button>
              <button className='btn btn-danger btn-sm fw-bold px-8 ms-5 text-hover-none' type="button" onClick={onYes}>{intl.formatMessage({ id: 'BUTTON_YES_DELETE' })}</button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export { ConfirmModal };

