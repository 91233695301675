import React from 'react';
import './style.scss';

type Props = {
  className?: string,
  style?: any,
  color?: string,
}

const IconSpinner: React.FC<Props> = ({className = '', color = '#fff', style = {}}) => {
  return (
      <div className={`mc-icon-spinner-ios ${className}`} style={style}>
            <div className="mc-icon-spinner-ios-bar1" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar2" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar3" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar4" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar5" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar6" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar7" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar8" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar9" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar10" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar11" style={{background: color}} ></div>
            <div className="mc-icon-spinner-ios-bar12" style={{background: color}} ></div>
    </div>
  )
}

export { IconSpinner };

