import { ConfigProvider, DatePicker } from 'antd';
import locale from "antd/es/locale/ko_KR";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import React from 'react';
import { IParamsSearch } from '../../../api/models/_public';
import './style.scss';
import { useIntl } from 'react-intl';

const { RangePicker } = DatePicker;

type Props = {
  handleSearch?: (s: IParamsSearch) => void
  fromDate?: string | null,
  endDate?: string | null,
  value?: [string, string],
  isBgWhite?: boolean,
  isLabel?: boolean
}

const dateFormat = 'YYYY-MM-DD';

const rangePresets: any[] = [
  {
    label: '7일 전',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: '14일 전',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: '30일 전',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: '90일 전',
    value: [dayjs().add(-90, 'd'), dayjs()],
  }
];


const RangePickerCustom: React.FC<Props> = ({ fromDate = null, endDate = null, value = ['', ''], handleSearch, isBgWhite = false, isLabel = true }) => {
  const intl = useIntl();
  const defaultValue: any = [(value[0] ? dayjs(value[0], dateFormat) : ''), (value[1] ? dayjs(value[1], dateFormat) : '')];
  const handleChange = (_dates: any, dateStrings: [string, string]) => {
    if (dateStrings?.length === 2 && handleSearch) {
      handleSearch({ start_date: dateStrings[0], end_date: dateStrings[1] });
    }
  }

  return (
    <div>
      {isLabel && (<label className="form-label text-muted  fs-6">{intl.formatMessage({ id: 'LABEL_CREATE_DATE' })}:</label>)}
      <div className={`range-picker-custom ${isBgWhite ? 'is-white' : ''}`} >
        <ConfigProvider locale={locale}>
          <RangePicker
            onChange={handleChange}
            value={defaultValue}
            presets={rangePresets}
          />
        </ConfigProvider>
      </div>
    </div>

  )
}

export { RangePickerCustom };

