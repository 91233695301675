import { AuthModel } from "../../../../_metacrew/api/models/_auth"

const AUTH_LOCAL_STORAGE_KEY = 'mc-auth-v'
const CURRENT_APP_LOCAL_STORAGE_KEY = 'mc-current-app-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}


const getCurrentApp = (): string | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(CURRENT_APP_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const currentApp: string = JSON.parse(lsValue) as string
    if (currentApp) {
      // You can easily check auth_token expiration also
      return currentApp
    }
  } catch (error) {
    console.error('CURRENT APP LOCAL STORAGE PARSE ERROR', error)
  }
}

const setCurrentApp = (idApp: string) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(idApp)
    localStorage.setItem(CURRENT_APP_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('CURRENT APP  LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeCurrentApp = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(CURRENT_APP_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('CURRENT APP  LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, getCurrentApp, setCurrentApp, removeCurrentApp, CURRENT_APP_LOCAL_STORAGE_KEY}
