const QUERIES = {
  USERS_LIST: 'users-list',
  GROUPS_LIST: 'groups-list',
  SETTING_GROUP: 'setting-group',
  APPS_LIST: 'apps-list',
  CATEGORIES_LIST: 'categories-list',
  CATEGORIES_LIST_NO_PAGINATION: 'categories-list-no-pagination',
  STICKERS_LIST: 'stickers-list',
  HASHTAGS_LIST: 'hashtags-list',
  BAD_KEYWORDS_LIST: 'bad-keywords',
  DASHBOARD: 'dashboard'
}

export {QUERIES}
